import styles from "./uploader.module.css";
import vpnIcon_selected from "../../images/vpn_icon_selected.svg";

export const File = ({name, className}) => {
    return (
        <div className={`${styles.file} ${className ?? ""}`}>
            <img className={styles.vpnicon_selected} src={vpnIcon_selected} alt="selected vpn file" />
            <p>{name}</p>
        </div>
    )
}