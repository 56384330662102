import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import FileSaver from "file-saver"

import fetchFromApi from "../../fetchFromApi";

import Uploader from "./uploader";
import RadioButton from "../common/radio-button";
import Button from "../common/button";

import styles from "./form.module.css";

import downloadlogoDisabed from "../../images/download_disabled.svg";
import downloadlogo from "../../images/download.svg";

export default () => {
    const vpnOptions = ["vpn_always", "vpn_outside_ssid"]

    const {t} = useTranslation();
    const [hostnames, setHostnames] = useState("");
    const [ssid, setSsid] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [selectedOption, setSelectedOption] = useState(vpnOptions[1]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await signOvpnFile();
    }

    const signOvpnFile = async () => {
        const data = new FormData()
        data.append('file', selectedFile)
        data.append('hostnames', hostnames)
        data.append('ssid', ssid)

        try {
            const response = await fetchFromApi('/api/v1/signme', {
                method: 'POST',
                body: data
            });
            if (response.ok) {
                FileSaver.saveAs(await response.blob(), selectedFile.name.split('.').slice(0, -1).join('.') + '.mobileconfig');
            }
            return response.ok;
        } catch (error) {
            console.error(error);
        }
    }

    const handleFileInputChange = (event) => {
        if (event !== null) {
            setSelectedFile(event);
            setFileName(event.name);
        }
        else {
            setSelectedFile(null);
            setFileName("");
        }
    }

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        setSsid( value === "vpn_always" ? "" : ssid);
    }

    const getIcon = () => {
        if (fileName === "" || hostnames === "") {
            return downloadlogoDisabed;
        }
        else {
            return downloadlogo;
        }
    }

    return (
        <form className={styles.form}>
            <h2>{t('main.step1-title')}</h2>
            <label htmlFor='ovpn-file'>{t('main.ovpn-file')}</label>
            <Uploader  id='ovpn-file' onChange={handleFileInputChange} />

            <h2>{t('main.step2-title')}</h2>
            <label htmlFor='hostnames' >{t('main.host-name')}</label>
            <input
                id='hostnames'
                name='hostnames'
                type='text'
                placeholder={t('main.host-name-placeholder')}
                tabIndex={0}
                autoFocus
                spellCheck={false}
                value={hostnames}
                onChange={(e) => setHostnames(e.target.value)} />
            <label>
                <sup>{t('main.host-name_additional')}</sup>
            </label>

            <h2>{t('main.step3-title')}</h2>
            <label style={{marginBottom: '12px'}}>{t('main.ssid_title')}</label>
            {vpnOptions.map(option =>
                <RadioButton
                    value={option}
                    checked={selectedOption === option}
                    onChangeHandler={handleOptionChange}
                    label={t(`main.${option}`)}
                    key={option}
                />
            )}

            <label htmlFor='ssid'>{t('main.ssid')}:</label>

            <input
                id='ssid'
                name='ssid'
                type='text'
                placeholder={t('main.ssid-placeholder')}
                tabIndex={1}
                spellCheck={false}
                value={ssid}
                disabled={selectedOption !== 'vpn_outside_ssid'}
                onChange={e => setSsid(e.target.value)} />
            <label disabled={selectedOption !== 'vpn_outside_ssid'}><sup>{t('main.ssid_additional')}</sup></label>

            <h2>{t('main.step4-title')}</h2>
            <Button tabIndex={2} disabled={fileName === "" || hostnames === ""} onClick={handleSubmit}>
                <img src={getIcon()} className={styles.downloadlogo} alt={t('main.create-button')} />{t('main.create-button')}
            </Button>
        </form>
    )
}