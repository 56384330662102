import {useRef} from "react";
import { Transition } from 'react-transition-group'

import styles from "./modal.module.css";

import iconClose from "../../images/close.svg";

const defaultStyle = {
    transition: `opacity 200ms ease-in-out`,
    opacity: 0,
    pointerEvents: "none"
}

const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1, pointerEvents: "all" },
    exiting:  { opacity: 1, pointerEvents: "none" },
    exited:  { opacity: 0 },
};

const dialogueDefaultStyle = {
    transition: `transform 250ms ease-out`,
    transform: "translateY(+40px)"
}

const dialogueStyles = {
    entering: { transform: "translateY(+40px)" },
    entered:  { transform: "translateY(0)"},
    exiting:  { transform: "translateY(0)"},
    exited:  { transform: "translateY(+40px)"},
};

const Modal = ({visible, children}) => {
    const nodeRef = useRef(null);
    const dialogueRef = useRef(null);

    return (
        <Transition
            nodeRef={nodeRef}
            in={visible}
            timeout={{
                appear: 0,
                enter: 0,
                exit: 100
            }}
        >
            {state => (
                <aside className={styles.overlay} ref={nodeRef} style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    <Transition
                        nodeRef={dialogueRef}
                        in={visible}
                        timeout={0}
                    >
                        {dialogueState => (
                            <dialog ref={dialogueRef} style={{
                                ...dialogueDefaultStyle,
                                ...dialogueStyles[dialogueState]
                            }}>
                                {children}
                            </dialog>
                        )}
                    </Transition>
                </aside>
            )}
        </Transition>
    )
}

const Header = ({children, onClose}) => (
    <header className={styles.header}>
        <p className={styles.header__text}>{children}</p>
        {onClose && (
            <button onClick={onClose}>
                <img src={iconClose} alt={"close"}/>
            </button>
        )}
    </header>
)
const Content = ({children}) => <section className={styles.content}>{children}</section>
const Footer = ({children}) => <footer className={styles.footer}>{children}</footer>

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;