import React, { useState, createRef } from "react";
import { useTranslation } from 'react-i18next';
import Dropzone from "react-dropzone";

import {File} from "./file";
import Button from "../common/button";

import styles from "./uploader.module.css";

import downloadIcon from '../../images/download_icon_filedrop.svg'
import vpnIcon from '../../images/vpn_icon.svg'

export default ({onChange}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(null);
  const dropZoneRef = createRef();

  const openDialog = e => {
    e.preventDefault();
    if(!dropZoneRef.current) return;
    dropZoneRef.current.open();
  }

  const handleDrop = acceptedFiles => {

    if (acceptedFiles.length > 0){
      setFileName(acceptedFiles[0]);
      onChange(acceptedFiles[0]);
    }
    else{
      setFileName(null);
      onChange(null);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropzone}>
        <div className={styles.centerBox}>
          <Dropzone onDrop={handleDrop} ref={dropZoneRef} accept="application/text, .ovpn"  >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img className={styles.downloadIcon} src={downloadIcon} alt="upload field" />
                <p className={styles.infoText}>{t('main.uploader.infotext')}</p>

              </div>
            )}
          </Dropzone>

          {fileName &&
            <File name={fileName.name} />
          }
        </div>
      </div>
      <Button onClick={openDialog} className={styles.fileOpenDialog} outlined>
        <img className={styles.vpnicon} src={vpnIcon} alt="select file" />{t('main.uploader.button')}
      </Button>
      {fileName &&
        <File name={fileName.name} className={styles.hideOnBig}/>
      }
    </div>
  );
}