import {useTranslation} from "react-i18next";

import styles from "./main.module.css";
import {PortalAddress} from "../../constants";

export default () => {
    const {t} = useTranslation();

    return (
        <section className={styles.intro}>
            <p>{t('main.intro')}</p>
            <p>{t('main.intro2')}</p>
            <ul>
                <li>
                    {t('main.intro3')} <a href={PortalAddress}>{t('main.introLink')}</a> {t('main.intro3a')}
                </li>
                <li>
                    {t('main.intro4')}
                </li>
            </ul>
        </section>
    )
}