import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import reportWebVitals from './reportWebVitals';

import './i18n';
import 'typeface-roboto';

import './styles/base-colors.css';
import './styles/colors.css';
import './styles/ise-variables.css';
import './styles/input.css';
import './styles/text.css';
import './index.css';

const supportsHistory = 'pushState' in window.history;

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH} forceRefresh={!supportsHistory}>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
