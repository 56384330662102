import React from 'react';
import styles from './button.module.css';
import {combineClassnames} from "../../react-utils";

function button({
  children,
  disabled = false,
  submit = false,
  onClick = () => { },
  className = null,
  style = null,
  autoFocus = false,
  outlined = false,
  ...other
}){

  let buttonClasses = className ? combineClassnames(styles.button, className) : styles.button;

  if(outlined){
    buttonClasses = combineClassnames(buttonClasses, styles["button--outlined"])
  }

  return (
    <button
      style={style}
      className={buttonClasses}
      disabled={disabled}
      type={submit ? "submit" : "button"}
      onClick={onClick}
      autoFocus={autoFocus}
      {...other}
    >
      {children}
    </button>)
};

  export default button;