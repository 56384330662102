import React from 'react';
import logo from '../../images/logo.svg';
import styles from './header.module.css';
import LanguageSwitch from './language-switch';

export default ({onOpenOverlay}) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
          <picture>
              <img src={logo} alt='my.ise logo' />
          </picture>
        <LanguageSwitch onOpenOverlay={onOpenOverlay}/>
      </div>
    </div>
  );
}

