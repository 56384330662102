import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './language-switch.module.css';
import switchIcon from '../../images/switch_language.svg';

export default ({onOpenOverlay}) => {
  const { t } = useTranslation();

  return (
      <button className={styles.button} onClick={() => {
        onOpenOverlay?.();
      }} >
        <img src={switchIcon} className={styles.switch_icon} alt="language switcher" />{t('header.language')}
      </button>
  )
}