import {useTranslation} from "react-i18next";

import styles from './main.module.css';

import u10 from '../../images/u10.svg'
import u7 from '../../images/u7.svg'
import u4 from '../../images/u4.svg'


export default () => {
    const {t} = useTranslation();

    return (
        <section>
            <h2>{t('main.step5-title')}</h2>
            <p>{t('main.step5')}</p>
            <p>{t('main.step5-1')}</p>
            <div className={styles.images_container}>
                <img src={u10} className={styles.images} alt="" />
                <img src={u7} className={styles.images_rotate} alt="" />
                <img src={u4} className={styles.images} alt="" />
            </div>
        </section>
    )
}