import React from 'react';
import { useTranslation } from 'react-i18next';

import IntroSection from "./introSection";
import Form from "./form"
import InstallSection from "./installSection";

import styles from './main.module.css';

export default () => {
  const {t} = useTranslation();

  return (
      <main className={styles.mainContent}>
        <div className={styles.content}>
          <h1>{t('main.title')}</h1>
          <IntroSection />
          <Form />
          <InstallSection />
        </div>
      </main>
  )
}