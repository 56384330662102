import React, {useState} from 'react';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Main from './components/main/main';
import LanguageChangeOverlay from "./components/LanguageChangeOverlay/LanguageChangeOverlay";

import styles from './app.module.css';

function App (){
    const [overlayVisible, setOverlayVisible] = useState(false);

    return (
        <div className={styles.container}>
              <Header onOpenOverlay={() => setOverlayVisible(!overlayVisible)}/>
              <Main />
              <Footer />
              <LanguageChangeOverlay visible={overlayVisible} onVisibilityChange={() => setOverlayVisible(!overlayVisible)}/>
        </div>
    );
}

export default App;