import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './footer.module.css';
import iseLogo from '../../images/ise-Logo-mit-Firmierung-sRGB.svg';
import i18n from "../../i18n";
import { Routes } from "../../constants";

import facebookIcon from '../../images/icons/socialicon-facebook-white.png';
import instagramIcon from '../../images/icons/socialicon-instagram-white.svg';
import linkedInIcon from '../../images/icons/socialicon-linkedin-white.png';
import xingIcon from '../../images/icons/socialicon-xing-white.png';
import youtubeIcon from '../../images/icons/socialicon-youtube-white.png';
export default withTranslation()(({t}) => (
    <footer className={styles.footer}>
      <section className={styles.footerSection}>
        <div className={styles.footerContainer}>
          <div className={styles.footerRow}>
            <div className={styles.footerColumn}>
              <img src={iseLogo} className={styles.footerIseLogo} alt="ise Logo" />

            </div>
            <div className={styles.footerColumn}>
              <p>
                <span className={styles.footerHeadLine}>{t('footer.contact')}</span>
                {t('contact.street')} <br />
                {t('contact.city')} <br />
                {t('contact.country')}
              </p>

            </div>
            <div className={styles.footerColumn}>
              <p>
                <span className={styles.footerHeadLine}>{t('footer.consultation-sales')}</span>
                T&nbsp;&nbsp;<a href="tel:+49 441 680 06 11">+49 441 680 06 11</a><br/>
                F&nbsp;&nbsp;<a href="tel:+49 441 680 06 15">+49 441 680 06 15</a><br/>
                E&nbsp;&nbsp;<a href='mailto:vertrieb@ise.de'>vertrieb@ise.de</a><br />
              </p>
            </div>
            <div className={styles.footerColumn}>
              <p>
                <span className={styles.footerHeadLine}>{t('footer.general-office')}</span>
                T&nbsp;&nbsp;<a href="tel:+49 441 680 06 0">+49 441 680 06 0</a><br/>
                F&nbsp;&nbsp;<a href="tel:+49 441 680 06 10">+49 441 680 06 10</a><br/>
                E&nbsp;&nbsp;<a href='mailto:info@ise.de'>info@ise.de</a><br />
              </p>

            </div>
            <div className={styles.footerColumn}>
              <nav>
                <ul>
                  <li><a href={Routes.imprint(i18n.language)}>{t('footer.imprint')}</a></li>
                  <li><a href={Routes.support(i18n.language)}>{t('footer.support')}</a></li>
                  <li><a href={Routes.privacyPolicy(i18n.language)}>{t('footer.data-protection')}</a></li>
                  <li><a href={Routes.termsOfUse(i18n.language)}>{t('footer.terms-of-use')}</a></li>
                </ul>
              </nav>

            </div>

            <div className={styles.footerColumn}>
              <div className={styles.footerHeadLine}>{t('footer.follow-us')}</div>
              <div className={styles.socialLinks}>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/IndividuelleSoftwareundElektronikGmbH" target={"_blank"} rel="noreferrer" >
                      <img src={facebookIcon} alt={"Facebook"}/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ise_gmbh/" target={"_blank"} rel="noreferrer" >
                      <img src={instagramIcon} alt={"Instagram"}/>
                    </a>
                  </li>
                  <li>
                    <a href={"https://de.linkedin.com/company/ise-individuelle-software-und-elektronik-gmbh"} target={"_blank"} rel="noreferrer" >
                      <img src={linkedInIcon} alt={"LinkedIn"}/>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.xing.com/pages/iseindividuellesoftwareundelektronikgmbh"} target={"_blank"} rel="noreferrer" >
                      <img src={xingIcon} alt={"Xing"} />
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.youtube.com/channel/UCMxoUbuYjxaQhN3BzKdEZhw"} target={"_blank"} rel="noreferrer" >
                      <img src={youtubeIcon} alt={"Youtube"}/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
))