import styles from './radio-button.module.css';

export default ( { label, checked, onChangeHandler, value} ) => {
    return (
        <div className={styles.container}>
            <label className={styles.radio}>
                { label }
                <input type={"checkbox"} checked={checked} value={value} onChange={() => onChangeHandler(value)}></input>
                <span className={styles.checkmark}></span>
            </label>
        </div>

    )
}