const PortalAddress = 'https://my.ise.de';

const Routes = {
  main: '/',
  error: '/error',
  imprint: lang => `${PortalAddress}/${ lang }/support/imprint`,
  privacyPolicy: lang => `${PortalAddress}/${lang}/support/privacy_policy_terms_of_use`,
  termsOfUse: lang => `${PortalAddress}/${lang}/support/privacy_policy_terms_of_use#terms`,
  support: lang => `${PortalAddress}/${lang}/messages/new`,
};

export { Routes, PortalAddress };
