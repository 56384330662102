import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Languages } from './languages';

const backendUrl = () => {
  let root = process.env.REACT_APP_BASE_PATH;
  if (!root.endsWith('/')) {
    root = root + '/';
  }
  return `${root}locales/{{lng}}/{{ns}}.json`;
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    whitelist: Languages.map(lang => lang.key),
    initImmediate: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    joinArrays: '\n\n', // for markdown
    backend: {
      loadPath: backendUrl(),
    }
  });

i18n.format = string => string.replace('%lang', i18n.language);

export default i18n;
