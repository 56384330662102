import React, {useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';

import {Languages} from "../../languages";

import Modal from '../common/modal';
import Button from '../common/button'
import RadioButton from '../common/radio-button';

import styles from "./LanguageChangeOverlay.module.css";

export default ({visible, onVisibilityChange}) => {
    const {i18n, t} = useTranslation();
    const [ language, setLanguage ] = useState(i18n.language);

    useEffect(() => {
        if(visible) setLanguage(i18n.language)
    }, [visible, i18n.language])

    const apply = async () => {
        await i18n.changeLanguage(language);
        onVisibilityChange();
    }

    return (
        <Modal visible={visible}>
            <Modal.Header onClose={onVisibilityChange} />
            <Modal.Content>
                <h2 className={styles.label}>{t("language.select")}</h2>
                {Languages.map(l => {
                    return <RadioButton checked={language===l.key} onChangeHandler={() => setLanguage(l.key)} value={l.key} label={l.languageName} key={l.key}/>
                })}
            </Modal.Content>
            <Modal.Footer>
                <Button onClick={apply} outlined>{t("main.ok")}</Button>
                <Button onClick={onVisibilityChange} outlined>{t("main.cancel")}</Button>
            </Modal.Footer>
        </Modal>
    )
}