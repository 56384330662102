const combineClassnames = (fromProps, names) => {

  let combined = fromProps || '';
  if(combined.length > 0 && !combined.endsWith(' ')) {
    combined += ' ';
  }

  if(Array.isArray(names)) {

    return combined + names.filter(val => val).reduce((prev, curr) => `${prev} ${curr}`, '');
  }

  if(names) {

    return combined + names;
  }

  return combined;
};

export {combineClassnames};